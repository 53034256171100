import union from "lodash/union";
import { AUSTRALIA_ISO2, PHILIPPINES_ISO2, SINGAPORE_ISO2, UNITED_KINGDOM_ISO2 } from "services/countries/countries-service";
// in cloudinarry, upload both to staging and production (so you'll be able to test first)
export const SUPPORTED_LOGOS_NUMBER_OPTIONS = [
    "5",
    "7",
    "9",
    "10",
    "12",
    "15",
    "18"
];
export const SUPPORTED_LOGOS_NUMBER_FOR_NARROW_DISPLAY_OPTIONS = [
    "5",
    "7",
    "9",
    "10",
    "12",
    "15",
    "18"
];
export const LOGO_COMPANY_FIELDS = {
    GENERAL: "General",
    GENERAL_WHITE: "General (white)",
    GENERAL_BLACK: "General (black)",
    GENERAL_OLD: "General_Old",
    HEALTHCARE: "Healthcare",
    ENTERTAINMENT: "Entertainment",
    MARKETING: "Marketing",
    ENTERPRISE: "Enterprise",
    SOFTWARE: "Software",
    CONSTRUCTION: "Construction",
    RETAIL: "Retail",
    CRM: "CRM"
};
export const LOGO_COMPANY_COUNTRIES = {
    AUSTRALIA: "Australia",
    BRAZIL: "Brazil",
    FRANCE: "France",
    GERMANY: "Germany",
    ITALY: "Italy",
    JAPAN: "Japan",
    MEXICO: "Mexico",
    NETHERLANDS: "Netherlands",
    SEA: "SEA (South East Asia)",
    UK: "UK",
    SWITZERLAND: "Switzerland"
};
export const ISO2_TO_LOGO_COUNTRIES_MAP = {
    [UNITED_KINGDOM_ISO2]: LOGO_COMPANY_COUNTRIES.UK,
    [AUSTRALIA_ISO2]: LOGO_COMPANY_COUNTRIES.AUSTRALIA,
    [PHILIPPINES_ISO2]: LOGO_COMPANY_COUNTRIES.SEA,
    [SINGAPORE_ISO2]: LOGO_COMPANY_COUNTRIES.SEA
};
const Uber = {
    url: "/static/img/customers/logos-v2/uber.png",
    name: "Uber"
};
// const Adobe = {
//   url: "/static/img/customers/logos-v2/adobe.png",
//   name: "Adobe"
// };
const UniversalMusicGroup = {
    url: "/static/img/customers/logos-v2/universal.png",
    name: "Universal Music Group"
};
const UniversalMusicGroupWhite = {
    url: "/static/img/customers/logos-v2/Logos_white/universal.png",
    name: "Universal Music Group"
};
const UniversalMusicGroupBlack = {
    url: "/static/img/customers/logos-v2/Logos_black/universal.png",
    name: "Universal Music Group"
};
// const Hulu = {
//   url: "/static/img/customers/logos-v2/hulu.png",
//   name: "Hulu"
// };
// const Unilever = {
//   url: "/static/img/customers/logos-v2/unilever.png",
//   name: "Unilever"
// };
// const Abbott = {
//   url: "/static/img/customers/logos-v2/abbott.png",
//   name: "Abbott"
// };
const Genpact = {
    url: "/static/img/customers/logos-v2/genpact.png",
    name: "Genpact"
};
// const Humana = {
//   url: "/static/img/customers/logos-v2/humana.png",
//   name: "Humana"
// };
const FrontierDevelopments = {
    url: "/static/img/customers/logos-v2/frontier-developments-seeklogo.png",
    name: "Frontier Developments"
};
const Zippo = {
    url: "/static/img/customers/logos-v2/Retail_Zippo.png",
    name: "Zippo"
};
const Alexion = {
    url: "/static/img/customers/logos-v2/alexion.png",
    name: "Alexion"
};
const Anadarko = {
    url: "/static/img/customers/logos-v2/anadarko.png",
    name: "Anadarko"
};
// const Gameloft = {
//   url: "/static/img/customers/logos-v2/gameloft.png",
//   name: "Gameloft"
// };
const Spinmaster = {
    url: "/static/img/customers/logos-v2/spinmaster.png",
    name: "Spinmaster"
};
const InteriorLogic = {
    url: "/static/img/customers/logos-v2/interior_logic.png",
    name: "Interior Logic"
};
const AmericanFamilyInsurance = {
    url: "/static/img/customers/logos-v2/american_family_insurance.png",
    name: "American Family Insurance"
};
const Deezer = {
    url: "/static/img/customers/logos-v2/deezer.png",
    name: "Deezer"
};
const Barvado = {
    url: "/static/img/customers/logos-v2/barvado.png",
    name: "Barvado"
};
const Briggs = {
    url: "/static/img/customers/logos-v2/briggs_stratton_corporation.png",
    name: "Briggs"
};
const CarndoChemrisk = {
    url: "/static/img/customers/logos-v2/cardno_chemrisk.png",
    name: "Carndo Chemrisk"
};
// const ReiCom = { url: "/static/img/customers/logos-v2/rei.com.png", name: "Reicom" };
const AeroMexico = {
    url: "/static/img/customers/logos-v2/aeromexico.png",
    name: "Aero Mexico"
};
const ProcoreTechnology = {
    url: "/static/img/customers/logos-v2/procore_technology.png",
    name: "Procore Technology"
};
// const FoxNews = { url: "/static/img/customers/logos-v2/fox_news.png", name: "Fox News" };
// const AllState = { url: "/static/img/customers/logos-v2/allstate.png", name: "Allstate" };
const Outbrain = {
    url: "/static/img/customers/logos-v2/outbrain.png",
    name: "Outbrain"
};
// const Delta = { url: "/static/img/customers/logos-v2/delta.png",  name: "Delta" };
const Emerson = {
    url: "/static/img/customers/logos-v2/emerson.png",
    name: "Emerson"
};
const CardinalHealth = {
    url: "/static/img/customers/logos-v2/cardinal_health.png",
    name: "Cardinal Health"
};
// const IBM = { url: "/static/img/customers/logos-v2/ibm.png",  name: "IBOM" };
// const WeWork = { url: "/static/img/customers/logos-v2/wework.png",  name: "WeWork" };
// const MITFederalCredit = {
//   url: "/static/img/customers/logos-v2/mit_federal_credit_union.png",
//   name: "MIT Federal Credit"
// };
const Marriott = {
    url: "/static/img/customers/logos-v2/marriott.png",
    name: "Marriott"
};
const Mckesson = {
    url: "/static/img/customers/logos-v2/mckesson.png",
    name: "Mckesson"
};
// const Corning = { url: "/static/img/customers/logos-v2/corning.png", name: "Corning" };
// const Tesla = { url: "/static/img/customers/logos-v2/tesla.png", name: "Tesla" };
// const Naic = { url: "/static/img/customers/logos-v2/naic.png", name: "Naic" };
// const Paypal = {
//   url: "/static/img/customers/logos-v2/paypal.png",
//   name: "Paypal"
// };
const Glossier = {
    url: "/static/img/customers/logos-v2/glossier.png",
    name: "Glossier"
};
const GlossierWhite = {
    url: "/static/img/customers/logos-v2/Logos_white/glossier.png",
    name: "Glossier"
};
const GlossierBlack = {
    url: "/static/img/customers/logos-v2/Logos_black/glossier.png",
    name: "Glossier"
};
const Kroger = {
    url: "/static/img/customers/logos-v2/kroger.png",
    name: "Kroger"
};
const Walmart = {
    url: "/static/img/customers/logos-v2/walmart.png",
    name: "Walmart"
};
const NipponOil = {
    url: "/static/img/customers/logos-v2/nippon_oil_corporation.png",
    name: "NipponOil"
};
// const Visa = {
//   url: "/static/img/customers/logos-v2/visa.png",
//   name: "Visa"
// };
// const CultureTrip = {
//   url: "/static/img/customers/logos-v2/culture_trip.png",
//   name: "Culture Trip"
// };
// const ClasenChocolate = {
//   url: "/static/img/customers/logos-v2/clasen_of_chocolate.png",
//   name: "Clasen Chocolate"
// };
// const Aecom = { url: "/static/img/customers/logos-v2/aecom.png", name: "Aecom" };
const ADP = {
    url: "/static/img/customers/logos-v2/adp.png",
    name: "ADP"
};
const BD = {
    url: "/static/img/customers/logos-v2/bd.png",
    name: "BD"
};
const BDWhite = {
    url: "/static/img/customers/logos-v2/Logos_white/bd.png",
    name: "BD"
};
const BDBlack = {
    url: "/static/img/customers/logos-v2/Logos_black/bd.png",
    name: "BD"
};
const HCAHealthcare = {
    url: "/static/img/customers/logos-v2/hca_healthcare.png",
    name: "HCA Healthcare"
};
const Peloton = {
    url: "/static/img/customers/logos-v2/peloton.png",
    name: "Peloton"
};
const TennisAustralia = {
    url: "/static/img/customers/logos-v2/tennis_australia.png",
    name: "Tennis Australia"
};
// const CBRE = { url: "/static/img/customers/logos-v2/cbre.png", name: "CBRE" };
const Exelon = {
    url: "/static/img/customers/logos-v2/exelon.png",
    name: "Exelon"
};
const HenrySchein = {
    url: "/static/img/customers/logos-v2/henry_schein.png",
    name: "Henry Schein"
};
const Davita = {
    url: "/static/img/customers/logos-v2/davita.png",
    name: "Davita"
};
// const Oneok = { url: "/static/img/customers/logos-v2/oneok.png", name: "Oneok" };
// const Parker = { url: "/static/img/customers/logos-v2/parker.png", name: "Parker" };
const PVH = {
    url: "/static/img/customers/logos-v2/Retail_PVH.png",
    name: "PVH"
};
// const Sempra = { url: "/static/img/customers/logos-v2/sempra.png", name: "Sampra" };
const Stryker = {
    url: "/static/img/customers/logos-v2/stryker.png",
    name: "Stryker"
};
const ThermoFisher = {
    url: "/static/img/customers/logos-v2/thermo_fisher.png",
    name: "Thermo Fisher"
};
const Univar = {
    url: "/static/img/customers/logos-v2/univar.png",
    name: "Univar"
};
const UsFoods = {
    url: "/static/img/customers/logos-v2/us_foods.png",
    name: "UsFoods"
};
const WestRock = {
    url: "/static/img/customers/logos-v2/westrock.png",
    name: "WestRock"
};
// const YUM = { url: "/static/img/customers/logos-v2/yum.png", name: "YUM" };
const Caseys = {
    url: "/static/img/customers/logos-v2/caseys.png",
    name: "Caseys"
};
// const Ascential = { url: "/static/img/customers/logos-v2/ascential.png", name: "Ascential" };
const CrossRiverBank = {
    url: "/static/img/customers/logos-v2/cross_river_bank.png",
    name: "Cross River Bank"
};
const PrimonialHolding = {
    url: "/static/img/customers/logos-v2/primonial_holding.png",
    name: "Primonial Holding"
};
const Prohibition_vapes = {
    url: "/static/img/customers/logos-v2/prohibition_vapes.png",
    name: "Prohibition Vapes"
};
const AlbericiGroup = {
    url: "/static/img/customers/logos-v2/alberici_group.png",
    name: "Alberic Group"
};
const UniversityOfLouisville = {
    url: "/static/img/customers/logos-v2/university_of_louisville.png",
    name: "University Of Louisville"
};
const GamingLabs = {
    url: "/static/img/customers/logos-v2/gaming_labs.png",
    name: "Gaming Labs"
};
const Terracon = {
    url: "/static/img/customers/logos-v2/terracon.png",
    name: "Terracon"
};
const VeriskAnalytics = {
    url: "/static/img/customers/logos-v2/verisk_analytics.png",
    name: "Verisk Analytics"
};
// const GuidWellSource = {
//   url: "/static/img/customers/logos-v2/guidewell_source.png",
//   name: "GuideWell Source"
// };
const CushmanAndWakefield = {
    url: "/static/img/customers/logos-v2/cushman_and_wakefield.png",
    name: "Cushman & Wakefield"
};
const AmericanDentalAssociation = {
    url: "/static/img/customers/logos-v2/american_dental_association.png",
    name: "American Dental Association"
};
const Transport = {
    url: "/static/img/customers/logos-v2/transport-for-greater-manchester.png",
    name: "Transport"
};
// const Web = { url: "/static/img/customers/logos-v2/web.com.png", name: "Web" };
const HamiltonLaneAdvisors = {
    url: "/static/img/customers/logos-v2/hamilton_lane_advisors.png",
    name: "Hamilton Land Advisors"
};
// const DineInFreshDbaPlated = {
//   url: "/static/img/customers/logos-v2/dineinfresh_dba_plated.png",
//   name: "DineInFresh"
// };
const SouthTexasCollege = {
    url: "/static/img/customers/logos-v2/south_texas_college.png",
    name: "South Texas College"
};
const LMBopco = {
    url: "/static/img/customers/logos-v2/lmb_opco.png",
    name: "Lmb Opco"
};
// const BBC = {
//   url: "/static/img/customers/logos-v2/bbc.png",
//   name: "BBC"
// };
// const FrankeKitchenSystems = {
//   url: "/static/img/customers/logos-v2/franke_kitchen_systems.png",
//   name: "Franke Kitchen Systems"
// };
const InsightVenturePartners = {
    url: "/static/img/customers/logos-v2/insight_venture_partners.png",
    name: "Insights Venture Partners"
};
// const Kumon = { url: "/static/img/customers/logos-v2/kumon.png", name: "Kumon" };
const LiebermanResearch = {
    url: "/static/img/customers/logos-v2/lieberman_research.png",
    name: "Lieberman Research"
};
const Novocure = {
    url: "/static/img/customers/logos-v2/novocure.png",
    name: "Novocure"
};
// const Tarkett = { url: "/static/img/customers/logos-v2/tarkett.png", name: "Tarkett" };
// const IronSource = { url: "/static/img/customers/logos-v2/ironsource.png",  name: "IronSource" };
// new country logos
const BnpParibas = {
    url: "/static/img/customers/logos-v2/bnpparibas.png",
    name: "BNP Paribas"
};
// const Bosch = {
//   url: "/static/img/customers/logos-v2/bosch.png",
//   name: "Bosch"
// };
const CocaCola = {
    url: "/static/img/customers/logos-v2/coca_cola.png",
    name: "Coca Cola"
};
const CocaColaWhite = {
    url: "/static/img/customers/logos-v2/Logos_white/cocacola.png",
    name: "Coca Cola"
};
const CocaColaBlack = {
    url: "/static/img/customers/logos-v2/Logos_black/cocacola.png",
    name: "Coca Cola"
};
const Globoplay = {
    url: "/static/img/customers/logos-v2/globoplay.png",
    name: "Globoplay"
};
const Loreal = {
    url: "/static/img/customers/logos-v2/loreal.png",
    name: "Loreal"
};
const RaiaDrogasil = {
    url: "/static/img/customers/logos-v2/raia_drogasil.png",
    name: "Raia Drogasil"
};
const Omelete = {
    url: "/static/img/customers/logos-v2/omelete.png",
    name: "Omelete"
};
const Hepmil = {
    url: "/static/img/customers/logos-v2/hempmil.png",
    name: "Hepmil"
};
const Sistic = {
    url: "/static/img/customers/logos-v2/SISTIC-Logo-PNG.png",
    name: "Sistic"
};
const NBC = {
    url: "/static/img/customers/logos-v2/nbc-logo.png",
    name: "NBC"
};
const DiscoveryChannel = {
    url: "/static/img/customers/logos-v2/discovery_channel_logo.png",
    name: "Discovery Channel"
};
const SkyBrasil = {
    url: "/static/img/customers/logos-v2/sky-brasil.jpg",
    name: "Sky Brasil"
};
const Carrefour = {
    url: "/static/img/customers/logos-v2/carrefour.png",
    name: "Carrefour"
};
const CarrefourWhite = {
    url: "/static/img/customers/logos-v2/Logos_white/carrefour.png",
    name: "Carrefour"
};
const CarrefourBlack = {
    url: "/static/img/customers/logos-v2/Logos_black/carrefour.png",
    name: "Carrefour"
};
const Sanofi = {
    url: "/static/img/customers/logos-v2/sanofi.png",
    name: "Sanofi"
};
const Accor = {
    url: "/static/img/customers/logos-v2/accor.png",
    name: "Accor"
};
const Bayer = {
    url: "/static/img/customers/logos-v2/bayer.png",
    name: "Bayer"
};
const Zhdk = {
    url: "/static/img/customers/logos-v2/zhdk.png",
    name: "Zhdk"
};
const Freitag = {
    url: "/static/img/customers/logos-v2/freitag.png",
    name: "Freitag"
};
const PicPay = {
    url: "/static/img/customers/logos-v2/picpay.png",
    name: "PicPay"
};
// const Wildlife = {
//   url: "/static/img/customers/logos-v2/wildlife.png",
//   name: "Wildlife"
// };
// const Magalu = {
//   url: "/static/img/customers/logos-v2/magalu.png",
//   name: "Magalu"
// };
// const Nubank = {
//   url: "/static/img/customers/logos-v2/nubank.png",
//   name: "Nubank"
// };
const BtgPactual = {
    url: "/static/img/customers/logos-v2/btg-pactual.png",
    name: "BtgPactual"
};
// const Ifood = {
//   url: "/static/img/customers/logos-v2/ifood.png",
//   name: "Ifood"
// };
const Wix = {
    url: "/static/img/customers/logos-v2/wix.png",
    name: "Wix"
};
const Eni = {
    url: "/static/img/customers/logos-v2/eni.png",
    name: "Eni"
};
// const Moncler = {
//   url: "/static/img/customers/logos-v2/moncler.png",
//   name: "Moncler"
// };
// const Prometeia = {
//   url: "/static/img/customers/logos-v2/prometeia.png",
//   name: "Prometeia"
// };
const AsRoma = {
    url: "/static/img/customers/logos-v2/as_roma.png",
    name: "AS Roma"
};
const RomaMobilita = {
    url: "/static/img/customers/logos-v2/mobilita.png",
    name: "Roma Mobilita"
};
const SoftwareAG = {
    url: "/static/img/customers/logos-v2/software_ag.png",
    name: "Software AG"
};
const BauerMediaGroup = {
    url: "/static/img/customers/logos-v2/bauer-media-group.png",
    name: "Bauer Media Group"
};
// const NHL = {
//   url: "/static/img/customers/logos-v2/nhl.png",
//   name: "NHL"
// };
// const Hubspot = {
//   url: "/static/img/customers/logos-v2/hubspot.png",
//   name: "Hubspot"
// };
const MarsWrigley = {
    url: "/static/img/customers/logos-v2/mars_wrigley.png",
    name: "Mars Wrigley"
};
const McCan = {
    url: "/static/img/customers/logos-v2/maccan.png",
    name: "McCan"
};
const Canva = {
    url: "/static/img/customers/logos-v2/canva.png",
    name: "Canva"
};
const CanvaWhite = {
    url: "/static/img/customers/logos-v2/Logos_white/canva.png",
    name: "Canva"
};
const CanvaBlack = {
    url: "/static/img/customers/logos-v2/Logos_black/canva.png",
    name: "Canva"
};
// const ElectronicArts = {
//   url: "/static/img/customers/logos-v2/Electronic_Arts.png",
//   name: "Electronic Arts"
// };
// const NBCUniversal = {
//   url: "/static/img/customers/logos-v2/NBCUniversal.png",
//   name: "NBC Universal"
// };
const Ebanx = {
    url: "/static/img/customers/logos-v2/ebanx.png",
    name: "Ebanx"
};
const Movile = {
    url: "/static/img/customers/logos-v2/movile.png",
    name: "Movile"
};
const Riachuelo = {
    url: "/static/img/customers/logos-v2/riachuelo.png",
    name: "Riachuelo"
};
const Even = {
    url: "/static/img/customers/logos-v2/even.png",
    name: "Even"
};
const Playtech = {
    url: "/static/img/customers/logos-v2/playtech.png",
    name: "Playtech"
};
const Datacom = {
    url: "/static/img/customers/logos-v2/datacom.png",
    name: "Datacom"
};
const Lightspeed = {
    url: "/static/img/customers/logos-v2/lightspeed.png",
    name: "Lightspeed"
};
// const Nautica = {
//   url: "/static/img/customers/logos-v2/nautica.png",
//   name: "Nautica"
// };
const Eisai = {
    url: "/static/img/customers/logos-v2/Eisai.png",
    name: "Eisai"
};
const Wella = {
    url: "/static/img/customers/logos-v2/Retail_Wella.png",
    name: "Wella"
};
const NipponGases = {
    url: "/static/img/customers/logos-v2/nippon.png",
    name: "NipponGases"
};
const HoltCat = {
    url: "/static/img/customers/logos-v2/HoltCat.png",
    name: "HoltCat"
};
const HoltCatWhite = {
    url: "/static/img/customers/logos-v2/Logos_white/Holt.png",
    name: "HoltCatWhite"
};
const HoltCatBlack = {
    url: "/static/img/customers/logos-v2/Logos_black/Holt.png",
    name: "HoltCatBlack"
};
const BMW = {
    url: "/static/img/customers/logos-v2/BMW.png",
    name: "BMW"
};
const Falk = {
    url: "/static/img/customers/logos-v2/Falk.png",
    name: "Falk"
};
// const Motorola = {
//   url: "/static/img/customers/logos-v2/motorola.png",
//   name: "Motorola"
// };
const Aesop = {
    url: "/static/img/customers/Aesop.png",
    name: "Aesop"
};
const Levis = {
    url: "/static/img/customers/levis.png",
    name: "Levis"
};
const Carlsberg = {
    url: "/static/img/customers/carlsberg.png",
    name: "Carlsberg"
};
const Lionsgate = {
    url: "/static/img/customers/logos-v2/lionsgate.png",
    name: "Lionsgate"
};
const LionsgateWhite = {
    url: "/static/img/customers/logos-v2/Logos_white/lionsgate.png",
    name: "Lionsgate"
};
const LionsgateBlack = {
    url: "/static/img/customers/logos-v2/Logos_black/lionsgate.png",
    name: "Lionsgate"
};
const Herdez = {
    url: "/static/img/customers/logos-v2/herdez.png",
    name: "Herdez"
};
const CruzRuja = {
    url: "/static/img/customers/logos-v2/cruz_ruja.png",
    name: "Cruz Ruja"
};
// const Prosegur = {
//   url: "/static/img/customers/logos-v2/prosegur.png",
//   name: "Prosegur"
// };
const Coppel = {
    url: "/static/img/customers/logos-v2/coppel.png",
    name: "Coppel"
};
const Leboncoin = {
    url: "/static/img/customers/logos-v2/leboncoin.png",
    name: "Leboncoin"
};
const Axa = {
    url: "/static/img/customers/logos-v2/axa.png",
    name: "Axa"
};
const JakartaMRT = {
    url: "/static/img/customers/logos-v2/mrt-lakarta.png",
    name: "Jakarta MRT"
};
const Farfetch = {
    url: "/static/img/customers/logos-v2/farfetch.png",
    name: "Farfetch"
};
const PetLove = {
    url: "/static/img/customers/logos-v2/petlove.png",
    name: "PetLove"
};
const ArytonSenna = {
    url: "/static/img/customers/logos-v2/instituto_ayrton_senna.png",
    name: "Aryton Senna"
};
const Telefonica = {
    url: "/static/img/customers/logos-v2/o2_Telefonica.png",
    name: "Telefonica"
};
const Invitae = {
    url: "/static/img/customers/logos-v2/invitae.png",
    name: "Invitae"
};
const Hesta = {
    url: "/static/img/customers/logos-v2/hesta.png",
    name: "Hesta"
};
const NHS = {
    url: "/static/img/customers/logos-v2/sheffield_health.png",
    name: "NHS"
};
const DavidJones = {
    url: "/static/img/customers/logos-v2/david_jones.png",
    name: "David Jones"
};
const Kmart = {
    url: "/static/img/customers/logos-v2/kmart.png",
    name: "Kmart"
};
const Officeworks = {
    url: "/static/img/customers/logos-v2/officeworks.png",
    name: "Officeworks"
};
const NSW = {
    url: "/static/img/customers/logos-v2/nsw.png",
    name: "NSW"
};
const FlightCentre = {
    url: "/static/img/customers/logos-v2/flight_center.png",
    name: "Flight Centre"
};
const NuroBiz = {
    url: "/static/img/customers/logos-v2/nurobiz.png",
    name: "NuroBiz"
};
const Oxy = {
    url: "/static/img/customers/logos-v2/oxy.png",
    name: "Oxy"
};
const OxyWhite = {
    url: "/static/img/customers/logos-v2/Logos_white/Occidental-Petroleum-Logo_1.png",
    name: "OxyWhite"
};
const OxyBlack = {
    url: "/static/img/customers/logos-v2/Logos_black/Occidental-Petroleum-Logo_1.png",
    name: "OxyBlack"
};
const NutriAsia = {
    url: "/static/img/customers/logos-v2/nutri_asia.png",
    name: "NutriAsia"
};
const VerdantSolar = {
    url: "/static/img/customers/logos-v2/verdant_solar.png",
    name: "VerdantSolar"
};
const Nexplay = {
    url: "/static/img/customers/logos-v2/nexplay.png",
    name: "Oxy"
};
const Esker = {
    url: "/static/img/customers/logos-v2/esker.png",
    name: "Oxy"
};
const Takenaka = {
    url: "/static/img/customers/logos-v2/takenaka.png",
    name: "Oxy"
};
const JustCo = {
    url: "/static/img/customers/logos-v2/just_co.png",
    name: "Just Co"
};
const Icarros = {
    url: "/static/img/customers/logos-v2/icarros.png",
    name: "Icarros"
};
const Entreprenur = {
    url: "/static/img/customers/logos-v2/entrepreneur.png",
    name: "Entreprenur"
};
const Channel4 = {
    url: "/static/img/customers/logos-v2/channel_4.png",
    name: "Channel4"
};
const Nielsen = {
    url: "/static/img/customers/logos-v2/Nielsen.png",
    name: "Nielsen"
};
const WundermanThompson = {
    url: "/static/img/customers/logos-v2/Wunderman_Thompson.png",
    name: "WundermanThompson"
};
const MCSaatchi = {
    url: "/static/img/customers/logos-v2/M_C_Saatchi.png",
    name: "MCSaatchi"
};
const BustleDigitalGroup = {
    url: "/static/img/customers/logos-v2/Bustle.png",
    name: "BustleDigitalGroup"
};
const Frasers = {
    url: "/static/img/customers/logos-v2/FRAS.L.png",
    name: "Frasers"
};
const LondonBoroughOfHaringey = {
    url: "/static/img/customers/logos-v2/Haringey.png",
    name: "LondonBoroughOfHaringey"
};
const TheTravelCorporation = {
    url: "/static/img/customers/logos-v2/the-travel-corporation.png",
    name: "TheTravelCorporation"
};
const RoyalMail = {
    url: "/static/img/customers/logos-v2/Royal_Mail.png",
    name: "RoyalMail"
};
const Cover = {
    url: "/static/img/customers/logos-v2/cover.png",
    name: "Cover"
};
const Hitachi = {
    url: "/static/img/customers/logos-v2/Hitachi.png",
    name: "Hitachi"
};
const Moneytree = {
    url: "/static/img/customers/logos-v2/Moneytree.png",
    name: "Moneytree"
};
const ToyotaConiqAlpha = {
    url: "/static/img/customers/logos-v2/Toyotaconiqalpha.png",
    name: "Toyota Coniq Alpha"
};
const Renault = {
    url: "/static/img/customers/logos-v2/renault_group.png",
    name: "Renault"
};
const Engie = {
    url: "/static/img/customers/logos-v2/engie_logo.png",
    name: "Engie"
};
const JumboSupermarkten = {
    url: "/static/img/customers/logos-v2/Jumbo.png",
    name: "Jumbo"
};
const ErasmusUniversityRotterdam = {
    url: "/static/img/customers/logos-v2/Erasmus.png",
    name: "Erasmus University Rotterdam"
};
const Webfleet = {
    url: "/static/img/customers/logos-v2/wf.png",
    name: "Webfleet"
};
const Hunkemoller = {
    url: "/static/img/customers/logos-v2/Hunkemoeller.png",
    name: "Hunkemoller"
};
const ScotchAndSoda = {
    url: "/static/img/customers/logos-v2/Scotch_Soda.png",
    name: "Scotch & Soda"
};
const RayWhite = {
    url: "/static/img/customers/logos-v2/ray-white.png",
    name: "Ray White"
};
const Flybuys = {
    url: "/static/img/customers/logos-v2/Flybuys.png",
    name: "Flybuys"
};
const Taronga = {
    url: "/static/img/customers/logos-v2/Taronga_zoo.png",
    name: "Taronga Zoo"
};
const NTT = {
    url: "/static/img/customers/logos-v2/ntt-data-logo.png",
    name: "NTT"
};
const Lixil = {
    url: "/static/img/customers/logos-v2/lixil_logo.png",
    name: "Lixil"
};
const Decathlon = {
    url: "/static/img/customers/logos-v2/Retail_Decathlon.png",
    name: "Decathlon"
};
const DavidYurman = {
    url: "/static/img/customers/logos-v2/Retail_David.png",
    name: "David Yurman"
};
const PapaJohns = {
    url: "/static/img/customers/logos-v2/papa_johns.png",
    name: "Papa Johns"
};
const SmartCamp = {
    url: "/static/img/customers/logos-v2/smartcamp-logo.png",
    name: "Smart Camp"
};
const SUNO = {
    url: "/static/img/customers/logos-v2/SUNO.png",
    name: "SUNO"
};
const SportGroup = {
    url: "/static/img/customers/logos-v2/sport_group.png",
    name: "Sports Group"
};
const WelcomeToTheJungle = {
    url: "/static/img/customers/logos-v2/wttj_logo.png",
    name: "Welcome To The Jungle"
};
const COMPANIES_ASSETS = [
    Uber,
    Oxy,
    FrontierDevelopments,
    Zippo,
    Alexion,
    Anadarko,
    Spinmaster,
    InteriorLogic,
    AmericanFamilyInsurance,
    Deezer,
    Barvado,
    Briggs,
    CarndoChemrisk,
    Canva,
    Glossier,
    Lionsgate,
    AeroMexico,
    ProcoreTechnology,
    Outbrain,
    Emerson,
    CardinalHealth,
    Marriott,
    Mckesson,
    PapaJohns,
    Genpact,
    Kroger,
    Walmart,
    NipponOil,
    ADP,
    BD,
    HCAHealthcare,
    Peloton,
    TennisAustralia,
    Exelon,
    HenrySchein,
    Davita,
    PVH,
    Stryker,
    ThermoFisher,
    Univar,
    UsFoods,
    WestRock,
    Caseys,
    CrossRiverBank,
    PrimonialHolding,
    Prohibition_vapes,
    AlbericiGroup,
    UniversityOfLouisville,
    GamingLabs,
    Terracon,
    VeriskAnalytics,
    CushmanAndWakefield,
    AmericanDentalAssociation,
    Transport,
    HamiltonLaneAdvisors,
    SouthTexasCollege,
    LMBopco,
    Lionsgate,
    InsightVenturePartners,
    LiebermanResearch,
    Novocure,
    BnpParibas,
    CocaCola,
    Globoplay,
    RaiaDrogasil,
    Omelete,
    NBC,
    DiscoveryChannel,
    SkyBrasil,
    Carrefour,
    Sanofi,
    Accor,
    Bayer,
    BtgPactual,
    UniversalMusicGroup,
    Wix
];
const LOGOS_BY_COUNTRIES = {
    [LOGO_COMPANY_COUNTRIES.BRAZIL]: [
        Icarros,
        CocaCola,
        BtgPactual,
        PicPay,
        SUNO,
        RaiaDrogasil,
        PetLove,
        ArytonSenna,
        Even
    ],
    [LOGO_COMPANY_COUNTRIES.FRANCE]: [
        Uber,
        WelcomeToTheJungle,
        Carrefour,
        Sanofi,
        Renault,
        Engie,
        Leboncoin,
        BnpParibas,
        CocaCola
    ],
    [LOGO_COMPANY_COUNTRIES.MEXICO]: [
        Herdez,
        UniversalMusicGroup,
        CruzRuja,
        AeroMexico,
        Uber,
        CocaCola,
        BMW,
        Coppel
    ],
    [LOGO_COMPANY_COUNTRIES.GERMANY]: [
        SoftwareAG,
        Wella,
        BMW,
        BauerMediaGroup,
        Uber,
        CocaCola,
        Bayer,
        Axa,
        SportGroup
    ],
    [LOGO_COMPANY_COUNTRIES.ITALY]: [
        Eni,
        AsRoma,
        RomaMobilita,
        CocaCola
    ],
    [LOGO_COMPANY_COUNTRIES.JAPAN]: [
        UniversalMusicGroup,
        Canva,
        NipponGases,
        HoltCat,
        NuroBiz,
        CocaCola,
        Cover,
        Eisai,
        Zippo,
        Uber,
        ToyotaConiqAlpha,
        Takenaka,
        Moneytree,
        SmartCamp,
        Hitachi,
        Lixil,
        NTT,
        Walmart
    ],
    [LOGO_COMPANY_COUNTRIES.AUSTRALIA]: [
        Canva,
        RayWhite,
        DavidJones,
        Kmart,
        Officeworks,
        Taronga,
        Flybuys,
        FlightCentre,
        MCSaatchi,
        CocaCola
    ],
    [LOGO_COMPANY_COUNTRIES.SEA]: [
        JakartaMRT,
        Canva,
        Sistic,
        NutriAsia,
        VerdantSolar,
        Nexplay,
        JustCo,
        Hepmil,
        Esker
    ],
    [LOGO_COMPANY_COUNTRIES.UK]: [
        Frasers,
        Lionsgate,
        LondonBoroughOfHaringey,
        TheTravelCorporation,
        Nielsen,
        Outbrain,
        MarsWrigley,
        Bayer,
        BMW,
        RoyalMail
    ],
    [LOGO_COMPANY_COUNTRIES.NETHERLANDS]: [
        JumboSupermarkten,
        ErasmusUniversityRotterdam,
        Webfleet,
        Hunkemoller,
        ScotchAndSoda,
        CocaCola,
        HoltCat,
        UniversalMusicGroup,
        Uber,
        Canva,
        Zippo
    ],
    [LOGO_COMPANY_COUNTRIES.SWITZERLAND]: [
        Carrefour,
        Axa,
        Bayer,
        BMW,
        CocaCola,
        Uber,
        Wella,
        Zhdk,
        Freitag
    ]
};
const LOGOS_BY_CLUSTER = {
    [LOGO_COMPANY_FIELDS.HEALTHCARE]: [
        CardinalHealth,
        BD,
        HCAHealthcare,
        HenrySchein,
        Stryker,
        Invitae,
        Hesta,
        Alexion,
        NHS
    ],
    [LOGO_COMPANY_FIELDS.ENTERTAINMENT]: [
        DiscoveryChannel,
        Entreprenur,
        NBC,
        Deezer,
        Telefonica,
        UniversalMusicGroup,
        Lionsgate,
        Channel4,
        Nielsen,
        SkyBrasil,
        BauerMediaGroup
    ],
    [LOGO_COMPANY_FIELDS.MARKETING]: [
        CocaCola,
        McCan,
        UniversalMusicGroup,
        WundermanThompson,
        Wix,
        MarsWrigley,
        Glossier,
        Loreal,
        HoltCat,
        MCSaatchi
    ],
    [LOGO_COMPANY_FIELDS.ENTERPRISE]: [
        Wix,
        Oxy,
        MarsWrigley,
        Canva,
        CocaCola,
        Lionsgate,
        Glossier,
        HoltCat,
        BD
    ],
    [LOGO_COMPANY_FIELDS.SOFTWARE]: [
        Wix,
        Outbrain,
        Playtech,
        Datacom,
        Lightspeed,
        Canva
    ],
    [LOGO_COMPANY_FIELDS.CONSTRUCTION]: [
        HoltCat,
        Falk,
        InteriorLogic,
        AlbericiGroup,
        BMW
    ],
    [LOGO_COMPANY_FIELDS.RETAIL]: [
        Kmart,
        Carrefour,
        Peloton,
        Glossier,
        Wella,
        Zippo,
        PVH,
        Decathlon,
        DavidYurman,
        BMW,
        Officeworks,
        Levis,
        Loreal,
        Carlsberg
    ],
    [LOGO_COMPANY_FIELDS.GENERAL]: [
        HoltCat,
        Canva,
        CocaCola,
        Oxy,
        Lionsgate,
        Carrefour,
        BD,
        Glossier,
        UniversalMusicGroup
    ],
    [LOGO_COMPANY_FIELDS.GENERAL_WHITE]: [
        HoltCatWhite,
        CarrefourWhite,
        OxyWhite,
        CocaColaWhite,
        CanvaWhite,
        BDWhite,
        GlossierWhite,
        UniversalMusicGroupWhite,
        LionsgateWhite
    ],
    [LOGO_COMPANY_FIELDS.GENERAL_BLACK]: [
        HoltCatBlack,
        UniversalMusicGroupBlack,
        CocaColaBlack,
        LionsgateBlack,
        CarrefourBlack,
        BDBlack,
        GlossierBlack,
        OxyBlack,
        CanvaBlack
    ],
    [LOGO_COMPANY_FIELDS.GENERAL_OLD]: [
        Uber,
        Carrefour,
        Genpact,
        FrontierDevelopments,
        Zippo,
        Alexion,
        Anadarko
    ],
    [LOGO_COMPANY_FIELDS.CRM]: [
        BustleDigitalGroup,
        BD,
        Canva,
        CocaCola,
        Lionsgate
    ]
};
export const getPersonalizedLogosByVisitorCountryIfNeeded = (companyField, country, personalizeUsingVisitorCountry, cloudfrontViewerCountry, abTests)=>{
    const supportedCompanyFields = [
        LOGO_COMPANY_FIELDS.GENERAL
    ];
    if (!supportedCompanyFields.includes(companyField)) {
        return country;
    }
    const fullyReleasedPersonalizedCountries = [
        UNITED_KINGDOM_ISO2,
        AUSTRALIA_ISO2,
        PHILIPPINES_ISO2,
        SINGAPORE_ISO2
    ];
    if (fullyReleasedPersonalizedCountries.includes(cloudfrontViewerCountry)) {
        return ISO2_TO_LOGO_COUNTRIES_MAP[cloudfrontViewerCountry];
    }
    // TODO: modify for CA logos test
    // const personalizedCountriesInTest = [AUSTRALIA_ISO2];
    // if (personalizedCountriesInTest.includes(cloudfrontViewerCountry)) {
    //   if (personalizeUsingVisitorCountry || shouldGetPersonalizedAuLogosTest(abTests, true)) {
    //     return ISO2_TO_LOGO_COUNTRIES_MAP[cloudfrontViewerCountry];
    //   }
    // }
    return country;
};
export const getCompaniesAssets = (options = {})=>{
    const { country, companyField, personalizeUsingVisitorCountry, cloudfrontViewerCountry, abTests } = options;
    let firstAssets = [];
    const selectedCountry = getPersonalizedLogosByVisitorCountryIfNeeded(companyField, country, personalizeUsingVisitorCountry, cloudfrontViewerCountry, abTests);
    if (selectedCountry && LOGOS_BY_COUNTRIES[selectedCountry]) {
        firstAssets = LOGOS_BY_COUNTRIES[selectedCountry];
    } else if (companyField && LOGOS_BY_CLUSTER[companyField]) {
        firstAssets = LOGOS_BY_CLUSTER[companyField];
    }
    return union([
        ...firstAssets,
        ...COMPANIES_ASSETS
    ]);
};
export const NUMBER_OF_COMPANIES = "9";
